<template>
    <div class="dashboard">
        <div class="wrapper">
            <div class="page-title">
                <div class="h1">Список пользователей</div>
                <div class="search-box">
                    <b-form-input
                        v-model="searchByFullName"
                        placeholder="Поиск пользователя по имени"
                        class="search-control"
                        debounce="700"
                    ></b-form-input>
                    <button v-if="searchByFullName" class="btn-crl cancel" @click="searchByFullName = ''"></button>
                    <b-icon v-else icon="search"></b-icon>
                </div>
            </div>
            <div class="auto-scroll-horizontal">
                <b-table
                    :busy="isBusy"
                    :filter="searchByFullName"
                    :fields="fields"
                    :items="usersProvider"
                    class="mb-0"
                    hover
                    :current-page="currentPage"
                    :per-page="perPage"
                    @row-clicked="showUserDetails"
                    empty-text="Нет доступных Пользователей"
                    show-empty
                    ref="UserTable"
                >

                    <template #cell(lastName)="data">
                        <div class="user-td">
                            <img v-if="data.item.avatarPath" :src="data.item.avatarPath" alt="">
                            <div class="user-name">
                                {{data.item.lastName}}
                                {{data.item.firstName}}
                                {{data.item.patroName}}
                            </div>
                        </div>
                    </template>

                    <template #cell(email)="data">
                        {{data.item.email ? data.item.email.address : '-'}}
                    </template>

                    <template #cell(role)="data">
                        {{ parseRole(data.item.role) }}
                    </template>

                    <template #cell(ban)="data">
                        <div class="status-cell">
                            <ban-status :status="data.item.ban"/>
                            <button class="btn-crl" @click="handleEdit(data.item)">
                                <b-icon icon="pencil-fill"></b-icon>
                            </button>
                        </div>
                    </template>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>

                    <template #empty="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                    <template #emptyfiltered="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                </b-table>
                <user-status :user="user" @updateStatus="updateStatus"/>
            </div>
        </div>

        <div v-if="totalUsers > perPage" class="pt-3">
            <b-pagination first-number last-number :total-rows="totalUsers" :per-page="perPage" v-model="currentPage"/>
        </div>

        <button
            v-b-modal.add-user
            class="add"
        >
            <b-icon icon="plus-circle"></b-icon>
        </button>

        <AddUser :isAdmin="false" @addUser="addUser"/>
    </div>
</template>

<script>
    import BanStatus from '@/components/user/BanStatus'
    import UserStatus from '@/components/modals/UserStatus'

    import { errorsAlertApi } from '@/helpers/errorsAlertApi'
    import { mapActions } from 'vuex'
    import AddUser from "@/components/modals/AddUser";

    export default {
        name: "users",
        components: {
            AddUser,
            BanStatus,
            UserStatus
        },
        methods: {
            ...mapActions([
                'getUsers',
                'setUserStatus',
                'addNewUser',
                'setUserRole'
            ]),
            usersProvider(ctx){

                const data = {
                    page: ctx.currentPage,
                };

                if(ctx.sortBy){
                    data.sortBy = ctx.sortBy;
                    data.sort = ctx.sortDesc ? 'desc' : 'asc';
                }

                if(ctx.filter){
                    data.searchBy = 'fullName';
                    // Без лишних пробелов
                    const str = ctx.filter.split(' ').filter(search => search !== '');
                    data.search = str.join(' ');
                }

                return this.getUsers(data).then((response) => {
                    this.totalUsers = response.totalCount;
                    this.users = response.results;
                    return this.users;
                })
                    .catch(() => {
                        return []
                    })
            },
            showUserDetails(data){
                this.$router.push(
                    { name: 'user-details', params: { userId: data._id } }
                )
            },
            parseRole(role){
                switch(role){
                    case 'ROOT':
                        return 'Рут';
                    case 'PREMIUM':
                        return 'Премиум';
                    case 'ADMIN':
                        return 'Админ';
                    default:
                        return 'Юзер';
                }
            },
            addUser(user){
                let userItem = null;
                const userData = {
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    language: user.language.lang
                };
                this.addNewUser(userData).then(userApi => {
                    userItem = userApi;
                    const userRoleAction = new Promise(resolve => {
                        if(user.hasOwnProperty('role')){
                            const userRoleData = {
                                userId: userApi._id,
                                role: user.role,
                                language: userApi.languageCode
                            };
                            this.setUserRole(userRoleData).then(userRoleApi => {
                                userItem = userRoleApi;
                                resolve();
                            })
                                .catch(error => {
                                    errorsAlertApi(error);
                                });
                        } else {
                            resolve();
                        }
                    });

                    Promise.all([userRoleAction]).then(() => {
                        this.users.push(userItem);
                        this.$refs.UserTable.refresh();
                        this.$bvModal.hide('add-user');
                        this.$router.push({
                            name: 'user-edit',
                            params: {
                                userId: userApi._id
                            }
                        })
                    })
                })
                    .catch(error => {
                        errorsAlertApi(error);
                });
            },
            handleEdit(user){
                this.user = user;
                this.$bvModal.show('user-status');
            },
            updateStatus(data){
                this.setUserStatus(data).then(userApiData => {
                    this.$bvModal.hide('user-status');

                    for (let i = 0, len = this.users.length; i < len; i++) {
                        const user = this.users[i];
                        if(user._id === userApiData._id){
                            Object.assign(this.users[i], userApiData);
                            break;
                        }
                    }
                })
                    .catch(error => {
                        errorsAlertApi(error);
                    });
            },
        },
        data(){
            return{
                users: [],
                isBusy: false,
                fields: [
                    {
                        key: 'lastName',
                        label: 'ФИО',
                        sortable: true,
                        class: 'w-25'
                    }, {
                        key: 'email.address',
                        label: 'E-mail',
                        sortable: true,
                        class: 'w-25'
                    }, {
                        key: 'role',
                        label: 'Роль',
                        sortable: true,
                    }, {
                        key: 'ban',
                        label: 'Статус',
                        sortable: true,
                        class: 'w-25'
                    }
                ],
                currentPage: 1,
                perPage: 10,
                searchByFullName: null,
                totalUsers: 0,
                user: null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-title{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .h1{
            margin: 0;
        }
    }
    .search-box{
        margin-left: auto;
        width: 360px;
        position: relative;
        .search-control{
            color: #013274;
            padding-right: 40px;
            border-color: #013274;
            &::placeholder{
                color: #013274;
            }
        }
        .bi{
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right: 10px;
            color: #013274;
        }
        .btn-crl{
            display: block;
            height: 100%;
            width: 40px;
            position: absolute;
            right: 0;
            top:0;
            &.cancel{
                &:before,&:after{
                    position: absolute;
                    content: ' ';
                    height: 13px;
                    width: 3px;
                    background-color: #818181;
                    border-radius: 10px;
                    top: 12px;
                    left: 18px;
                }
                &:before{
                    transform: rotate(45deg);
                }
                &:after{
                    transform: rotate(-45deg);
                }
            }
        }
    }
    .user{
        &-td {
            display: flex;
            align-items: center;
            min-height: 48px;
            img {
                width: 48px;
                height: 48px;
                margin-right: 10px;
                border-radius: 50%;
                overflow: hidden;
                object-fit: cover;
                flex: none;
            }
        }
    }
    .status-cell{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-crl{
            margin: 0 10px;
        }
    }
</style>